<template>
  <div class="yt-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item to="/manage/recruitment/job">职位管理</el-breadcrumb-item>
        <template v-if="breadcrumbs && breadcrumbs.length > 0">
          <el-breadcrumb-item v-for="(item, index) in breadcrumbs" :key="index" :to="item.to">{{ item.label }}</el-breadcrumb-item>
        </template>
        <el-breadcrumb-item>{{ modeEnum[mode] }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="yt-container">
      <el-form ref="form" :model="formData" :rules="ruleValidate" label-width="80px">
        <div class="border">
          <p class="title">基本信息</p>
        </div>
        <div class="yt-base-content">
          <div class="form-row">
            <el-form-item label="招聘类型" prop="recruitType" style="align-items: center">
              <el-radio-group v-model="formData.recruitType" style="margin-left: 22px">
                <el-radio :label="1">社招</el-radio>
                <el-radio :label="0">校招</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="岗位模型" prop="postId">
              <el-select v-model="formData.postId" filterable placeholder="请选择岗位模型">
                <el-option v-for="(type, key) in postOptions" :key="key" :value="type.id" :label="type.name"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="form-row">
            <el-form-item label="职位名称" prop="name">
              <template v-if="mode">
                <el-input v-model="formData.name" placeholder="请输入职位名称" />
              </template>
              <template v-else>
                {{ formData.name }}
              </template>
            </el-form-item>
            <el-form-item label="招聘部门" prop="departmentName">
              <el-input v-model="formData.departmentName" placeholder="请输入招聘部门" />
            </el-form-item>
          </div>
          <div class="form-row">
            <el-form-item label="职位类型" prop="jobType">
              <el-select v-model="formData.jobType" placeholder="请选择职位类型">
                <el-option v-for="(type, key) in jobTypeOptions" :key="key" :value="key" :label="type"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="工作地点" prop="workPlace">
              <el-select
                v-model="formData.workPlace"
                class="place-select"
                popper-class="place-list"
                :popper-append-to-body="false"
                multiple
                filterable
                allow-create
                default-first-option
                placeholder="请输入地点按下回车生成"
              >
              </el-select>
            </el-form-item>
          </div>
          <div class="form-row">
            <el-form-item label="招聘人数">
              <el-input-number v-model="formData.recruitmentNum" placeholder="若干" :max="10000" :controls="false" />
              <div class="word">
                <span style="margin-left: 6px">人</span>
                <span style="margin-left: 16px">(不填写为若干)</span>
              </div>
            </el-form-item>
            <el-form-item label="薪资待遇" prop="maxSalary">
              <el-input-number v-model="formData.minSalary" :controls="false" :precision="0" :min="0" />
              <span style="margin: 0 10px">-</span>
              <el-input-number v-model="formData.maxSalary" :controls="false" :precision="0" :min="0" />
              <div class="word">
                <span style="margin-left: 5px">元 / 月</span>
              </div>
              <div class="word">
                <span style="margin-left: 14px">(都不填写为面议)</span>
              </div>
            </el-form-item>
          </div>
          <div class="form-row">
            <el-form-item label="工作性质" style="align-items: center">
              <el-radio-group v-model="formData.workType" style="margin-left: 22px">
                <template v-for="(type, key) in workTypeOptions">
                  <el-radio :label="key">{{ type }}</el-radio>
                </template>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="上架时长" prop="expireTime">
              <el-select v-model="formData.expireTime" placeholder="请选择上架时长">
                <el-option v-for="(type, key) in expireTimeOptions" :key="key" :value="key" :label="type"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <el-form-item label="职位描述" prop="description" style="margin-bottom: 6px !important">
            <el-input
              type="textarea"
              placeholder="请输入内容"
              :maxLength="1000"
              :autosize="{ minRows: 3 }"
              v-model="formData.description"
              style="width:100%"
            />
          </el-form-item>
          <div class="tips">
            <span>您还可输入:</span>
            <span style="color:red;">{{ descriptionLimit }}</span>
            <span>个字</span>
          </div>
        </div>
        <div class="border" style="margin-top:10px;">
          <p class="title">职位要求</p>
        </div>
        <div class="yt-base-content">
          <div class="form-row">
            <el-form-item label="学历要求" prop="education">
              <el-select v-model="formData.education" placeholder="请选择">
                <el-option v-for="(education, key) in educationOptions" :key="key" :label="education" :value="key" />
              </el-select>
            </el-form-item>
            <el-form-item label="岗位经验" prop="experience">
              <el-select v-model="formData.experience" placeholder="请选择">
                <el-option v-for="(experience, key) in experienceOptions" :key="key" :label="experience" :value="key" />
              </el-select>
            </el-form-item>
          </div>
          <el-form-item label="职位要求" prop="requirements" style="margin-bottom: 6px !important">
            <el-input
              type="textarea"
              placeholder="请输入内容"
              :maxLength="1000"
              :autosize="{ minRows: 3 }"
              v-model="formData.requirements"
              style="width:100%;"
            />
          </el-form-item>
          <div class="tips">
            <span>您还可输入:</span>
            <span style="color:red;">{{ requirementsLimit }}</span>
            <span>个字</span>
          </div>
        </div>
        <div class="border" style="margin-top:10px;">
          <p class="title">测评要求</p>
        </div>
        <div class="yt-base-content">
          <div class="form-row">
            <el-form-item label="测评" prop="whetherExam" style="align-items: center">
              <el-radio-group v-model="formData.whetherExam" style="margin-left: 22px">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="formData.whetherExam" label="题量" prop="questionNum">
              <el-input-number v-model="formData.questionNum" :min="0" :max="999" :controls="false" :precision="0" placeholder="请输入题量" />
            </el-form-item>
          </div>
          <div class="form-row">
            <el-form-item v-if="formData.whetherExam" label="题库" prop="questionBankIds" style="width: 100%">
              <el-select v-model="formData.questionBankIds" placeholder="请选择" multiple filterable>
                <template v-for="item in questionBankList">
                  <el-option :key="item.questionBankId" :label="item.name" :value="item.questionBankId"></el-option>
                </template>
              </el-select>
            </el-form-item>
          </div>
          <div class="form-row" v-if="formData.whetherExam">
            <el-form-item label="总分" prop="totalScore">
              <el-input-number v-model="formData.totalScore" :min="0" :max="999" :controls="false" :precision="0" placeholder="请输入总分" />
            </el-form-item>
            <el-form-item label="总时长" prop="limitTime">
              <el-input-number v-model="formData.limitTime" :min="0" :max="999" :controls="false" :precision="0" placeholder="请输入总时长" />
              <div class="word">
                <span style="margin-left: 6px">分钟</span>
              </div>
            </el-form-item>
          </div>
        </div>
        <div class="border" style="margin-top:10px;">
          <p class="title">多渠道发布</p>
        </div>
        <div class="yt-base-content">
          <div class="form-row">
            <el-form-item label="渠道" style="width: 100%">
              <el-checkbox-group style="width: 100%; display: flex; flex-wrap: wrap" v-model="formData.enterpriseList">
                <el-checkbox label="boss直聘"></el-checkbox>
                <el-checkbox label="智联招聘"></el-checkbox>
                <el-checkbox label="猎聘网"></el-checkbox>
                <el-checkbox label="拉勾网"></el-checkbox>
                <el-checkbox label="前程无忧"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div class="yt-footer">
        <el-button @click="backTo">取消</el-button>
        <el-button type="primary" :loading="loading" @click="submit">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import jobApi from '@api/job'
import questionBankApi from '@api/questionBank'
import postApi from '@api/post'
export default {
  name: 'JobRelease',
  data() {
    const validateName = (rule, value, callback) => {
      if (this.$regSpace(value)) {
        callback(new Error('职位名称不能全为空格'))
      } else {
        callback()
      }
    }
    const validateMaxSalary = (rule, value, callback) => {
      if (this.formData.maxSalary <= this.formData.minSalary) {
        callback(new Error('薪资最大值不能小于等于最小值'))
      } else {
        callback()
      }
    }
    return {
      fromRoute: '',
      mode: 1, //模式 1: 新增, 0: 编辑
      modeEnum: {
        1: '发布',
        0: '编辑'
      },
      formData: {
        recruitType: 1,
        name: '',
        departmentName: '',
        jobType: '',
        postId: '',
        workPlace: [],
        recruitmentNum: undefined,
        minSalary: undefined,
        maxSalary: undefined,
        workType: '0',
        expireTime: '3', //上架时长
        description: '',
        education: '',
        experience: '',
        requirements: '',
        whetherExam: true, //是否测试
        questionBankIds: [], //题库
        questionNum: undefined, //题量
        totalScore: '100', //总分
        limitTime: undefined, //总时长
        enterpriseList: ['boss直聘', '智联招聘', '猎聘网', '前程无忧', '拉勾网'] //企业列表暂时没用到
      }, //表单
      ruleValidate: {
        recruitType: [{ required: true, message: '请选择招聘类型', trigger: 'change' }],
        name: [
          { required: true, message: '职位名称不能为空', trigger: 'blur' },
          { max: 20, message: '职位名称长度不能超过20个字符', trigger: 'blur' },
          { min: 1, message: '职位名称长度不小于1个字符', trigger: 'blur' },
          { validator: validateName, trigger: 'blur' }
        ],
        jobType: [{ required: true, message: '请选择职位类型', trigger: 'change' }],
        maxSalary: [{ validator: validateMaxSalary, trigger: 'blur' }],
        departmentName: [{ max: 50, message: '招聘部门长度不能超过50个字符', trigger: 'blur' }],
        postId: [{ required: true, message: '请选择岗位模型', trigger: 'change' }],
        workPlace: [{ required: true, message: '工作地点不能为空', trigger: 'blur' }],
        expireTime: [{ required: true, message: '请选择上架时长', trigger: 'change' }],
        description: [
          { required: true, message: '职位描述不能为空', trigger: 'blur' },
          { max: 1000, message: '职位要求长度不能超过1000个字符', trigger: 'blur' }
        ],
        education: [{ required: true, message: '请选择学历要求', trigger: 'change' }],
        experience: [{ required: true, message: '请选择工作经验', trigger: 'change' }],
        requirements: [
          { required: true, message: '职位要求不能为空', trigger: 'blur' },
          { max: 1000, message: '职位要求长度不能超过1000个字符', trigger: 'blur' }
        ],
        whetherExam: [{ required: true, message: '请选择是否测评', trigger: 'change' }],
        questionBankIds: [{ required: true, message: '请选择题库', trigger: 'change' }],
        questionNum: [{ required: true, message: '题量不能为空', trigger: 'blur' }],
        totalScore: [{ required: true, message: '总分不能为空', trigger: 'blur' }],
        limitTime: [{ required: true, message: '总时长不能为空', trigger: 'blur' }]
      },
      schoolRecruitmentOptions: [], //校招列表
      jobTypeOptions: [], //职位类型
      postOptions: [], //岗位模型
      workTypeOptions: [], //工作类型
      educationOptions: [], //学历要求
      experienceOptions: [], //工作经验
      expireTimeOptions: [], //上架时长
      questionBankList: [], //题库列表
      loading: false
    }
  },
  computed: {
    breadcrumbs() {
      const routeEnum = {
        jobDetail: [{ label: '查看详情', to: '/manage/recruitment/job/detail' }],
        jobList: []
      }
      return routeEnum[this.fromRoute]
    },
    descriptionLimit() {
      //计算剩余字数
      return 1000 - this.formData.description.length
    },
    requirementsLimit() {
      //计算剩余字数
      return 1000 - this.formData.requirements.length
    }
  },
  mounted() {
    let params = this.$handleParams('jobReleaseData')
    this.mode = parseInt(params.mode)
    this.fromRoute = params.fromRoute ? params.fromRoute : 'jobList'
    if (!this.mode) {
      this.formData = {
        ...params.detail,
        name: params.detail.jobName,
        jobType: params.detail.jobType.toString(),
        workType: params.detail.workType.toString(),
        education: params.detail.education.toString(),
        experience: params.detail.experience.toString(),
        expireTime: params.detail.expireTime.toString(),
        questionBankIds: params.detail.questionBankIds,
        minSalary: params.detail.minSalary ? params.detail.minSalary : undefined,
        maxSalary: params.detail.maxSalary ? params.detail.maxSalary : undefined,
        enterpriseList: ['boss直聘', '智联招聘', '猎聘网', '前程无忧', '拉勾网'] //企业列表暂时没用到
      }
    }
    this.getJobType()
    this.getAllPosts()
    this.getWorkType()
    this.getEducationType()
    this.getExperienceType()
    this.getAllSchoolRecruitmentName()
    this.getExpireTime()
    this.getQuestionBankList()
  },
  destroyed() {
    localStorage.removeItem('jobReleaseData')
  },
  methods: {
    //data
    getJobType() {
      //获取职位类型
      jobApi.getJobType().then(res => {
        this.jobTypeOptions = res.res
      })
    },
    getAllPosts() {
      //获取岗位模型
      postApi.getAllPosts().then(res => {
        this.postOptions = res.res
      })
    },
    getWorkType() {
      //获取工作性质
      jobApi.getWorkType().then(res => {
        this.workTypeOptions = res.res
      })
    },
    getEducationType() {
      //获取学历
      jobApi.getEducationType().then(res => {
        this.educationOptions = res.res
      })
    },
    getExperienceType() {
      //获取工作经验
      jobApi.getExperienceType().then(res => {
        this.experienceOptions = res.res
      })
    },
    getAllSchoolRecruitmentName() {
      //获取招聘类型
      jobApi.getAllSchoolRecruitmentName().then(res => {
        this.schoolRecruitmentOptions = res.res
      })
    },
    getExpireTime() {
      //获取上架时长
      jobApi.getExpireTime().then(res => {
        this.expireTimeOptions = res.res
      })
    },
    getQuestionBankList() {
      //获取题库列表
      questionBankApi.searchSimpleInfo().then(res => {
        this.questionBankList = res.res
      })
    },
    //event
    submit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.loading = true
          let payload = {
            ...this.formData,
            minSalary: this.formData.minSalary ? this.formData.minSalary : null,
            maxSalary: this.formData.maxSalary ? this.formData.maxSalary : null,
            recruitmentNum: this.formData.recruitmentNum !== '' ? this.formData.recruitmentNum : '若干',
            questionBankIds: this.formData.questionBankIds
          }
          jobApi
            .updateJob(payload)
            .then(res => {
              if (res.res === true) {
                this.$message.success(this.mode ? '发布职位成功' : '编辑职位成功')
                this.$router.push('/manage/recruitment/job')
              } else {
                this.loading = false
              }
            })
            .catch(() => {
              this.loading = false
            })
        }
      })
    },
    backTo() {
      this.$router.push({ name: this.fromRoute })
    }
  }
}
</script>

<style lang="less" scoped>
.yt-main {
  padding-top: 0;
}
.yt-container {
  height: auto;
  padding: 10px;
}
.el-form {
  width: 100%;
  .yt-base-content {
    background: #fff;
    padding: 20px 60px 0px 60px;
    margin-top: 2px;
  }
  .border {
    background: #fff;
    width: 100%;
    position: relative;
    .title {
      line-height: 40px;
      font-size: @medium;
      margin-left: 20px;
      &:before {
        content: '';
        width: 4px;
        height: 20px;
        position: absolute;
        left: 0;
        top: 10px;
        background-color: #2878ff;
        border-radius: 1px;
      }
    }
  }
  .form-row {
    .flexStyle(flex, space-between, flex-start);
    .el-form-item {
      width: calc((100% - 40px) / 2);
    }
  }
  .el-form-item {
    .flexStyle(flex, flex-start, flex-start);
    margin-bottom: 20px !important;
  }
  .word {
    color: #bfc1c6;
    font-size: @small;
    white-space: nowrap;
  }
  ::v-deep .el-form-item__label {
    flex-shrink: 0;
    font-size: 14px;
    color: #000000;
    white-space: nowrap;
  }
  ::v-deep .el-form-item__content {
    margin-left: 0 !important;
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  ::v-deep .el-input-inner {
    padding: 0 12px;
  }
  ::v-deep .el-textarea__inner {
    padding: 9px 12px;
    line-height: 24px;
  }
  ::v-deep .el-input-number {
    width: 100%;
    .el-input__inner {
      text-align: left;
    }
  }
  .el-cascader {
    width: 100%;
  }
  ::v-deep .el-select {
    width: 100%;
    &.place-select {
      .el-select__caret {
        display: none;
      }
      .el-tag {
        height: auto !important;
        white-space: pre-wrap;
        color: #333333;
        background-color: #f0f0f0;
        &:hover {
          color: #448bff;
          background-color: #edf4ff;
          .el-tag__close {
            background-color: #448bff;
          }
        }
        .el-tag__close {
          color: #ffffff;
          background-color: #c0c4cc;
        }
      }
    }
    .place-list {
      display: none;
    }
  }
  .tips {
    text-align: right;
    color: #bfc1c6;
    font-size: @medium;
    padding-bottom: 20px;
  }
  .yt-radio {
    font-size: @medium;
    color: #bfc1c6;
    ::v-deep .el-radio__label {
      color: #999999;
    }
    .yt-radio-group {
      margin-bottom: 20px !important;
      justify-content: flex-start;
      align-items: center;
    }
  }
  .el-radio__input.is-checked .el-radio__inner {
    border-color: #409eff;
    background: #409eff;
  }
  .el-radio {
    margin-right: 55px;
  }
}
.yt-footer {
  margin: 20px 0 10px;
  text-align: center;
  ::v-deep.el-button {
    i {
      font-size: @medium;
    }
  }
}
</style>
